<template>
  <div class="login_container">
    <!-- 头部 -->
    <!-- 头部 -->
    <van-cell is-link to="/HCardL" center value="切换用户">
      <!-- 使用 title 插槽来自定义标题 -->
      <template slot="title">
        <span class="custom-title">{{ userName }}</span>
      </template>
      <template slot="label">
        <span>{{ cardNo }}</span>
      </template>
      <van-icon
        slot="right-icon"
        class-prefix="icon"
        name="qiehuan"
        class="iconfont"
        color="#969799"
      />
    </van-cell>

    <van-cell center>
      <!-- 使用 title 插槽来自定义标题 -->
      <template slot="title">
        <span style="color: #57c4b7; font-size: 16px">{{ patientName }}</span>
        <span class="spanStyle">{{ deptName }}</span>
        <!-- <span class="spanStyle">{{ bedNo }}床</span> -->
        <span style="margin-left: 8px">{{ inpatientStatus }}</span>
      </template>
      <template slot="label">
        <div>
          <div>
            住院号：{{ inpatientNo }}
            <div style="float: right">入院日期：{{ inpatientDate }}</div>
          </div>
          <div>出院日期：{{ dischargeDate }}</div>
        </div>
      </template>
    </van-cell>

    <van-cell>
      <!-- 使用 title 插槽来自定义标题 -->
      <template slot="title">
        <div>
          预交总金额：{{ prepayCost }}
          <div style="float: right">
            总费用：
            <span style="color: #57c4b7">{{ totalCost }}</span>
          </div>
        </div>
      </template>
      <template slot="label">
        <div style="font-size: 14px">
          预交金余额：
          <span style="color: red">{{ balance }}</span>
        </div>
      </template>
    </van-cell>

    <van-cell>
      <!-- 使用 title 插槽来自定义标题 -->
      <template slot="title">
        <div style="display: flex">
          <van-field
            style="padding: 0; width: 200px"
            v-model="moneyValue"
            label="缴纳金额："
            placeholder="请输入金额"
          />元
        </div>
      </template>
      <template slot="label">
        <div style="display: flex; margin: 10px 0">
          <div style="width: 30%">
            <van-button
              plain
              :class="oneBtnStyle"
              @click="onOneclick"
              block
              color="#57c4b7"
              >1000</van-button
            >
          </div>
          <div style="width: 30%; margin-left: 5%">
            <van-button
              plain
              :class="twoBtnStyle"
              @click="onTwoclick"
              block
              color="#57c4b7"
              >2000</van-button
            >
          </div>
          <div style="width: 30%; margin-left: 5%">
            <van-button
              plain
              :class="threeBtnStyle"
              @click="onThreeclick"
              block
              color="#57c4b7"
              >3000</van-button
            >
          </div>
          <!-- <van-button plain  class="defaultBtn" color="#57c4b7">2000</van-button>
          <van-button plain  class="defaultBtn" color="#57c4b7">3000</van-button>-->
        </div>
        <div>
          <van-button
            block
            color="#57c4b7"
            round
            :disabled="disabled"
            @click="onclickPay"
            >立即充值</van-button
          >
        </div>
      </template>
    </van-cell>

    <div>
      <van-cell title="费用清单" is-link @click="onHFeeL" />
      <van-cell title="预交记录" is-link @click="onHFeeR" />
    </div>
    <div
      style="
        font-size: 12px;
        color: #d3d3d3;
        margin: 1% 40%;
        width: auto;
        height: 40px;
      "
    >
      <span>&copy;拓实银医</span>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant"; //弹出框
import store from "../../store";
import appconst from "../../lib/appconst";
import ajax from "../../lib/ajax";
// let patientid = store.getters["store/patientid"];
// patientid = "0000008866";
const OperatorId = "100002";
let orderid = "";
let inpatientSeriNo;
export default {
  data() {
    return {
      userId: 0,
      value1: 0,
      state: 0,
      show: false,
      userName: store.getters["store/userName"],
      cardNo: store.getters["store/cardNo"], //声明就诊卡号,
      // DepName: "暂无数据",
      bedNo: "暂无数据", //床位号
      inpatientStatus: "暂无数据", //住院状态
      patientName: "暂无数据", //住院信息:姓名
      deptName: "暂无数据", //住院信息:科室名
      inpatientNo: "暂无数据", //住院信息:住院号
      dischargeDate: "暂无数据", //出院时间
      inpatientDate: "暂无数据", //住院信息:入院日期,
      prepayCost: "暂无数据", //预缴总金额
      totalCost: "暂无数据", //费用总额
      balance: "暂无数据", //余额
      moneyValue: "",
      oneBtnStyle: "defaultBtn",
      twoBtnStyle: "defaultBtn",
      threeBtnStyle: "defaultBtn",
      drName: "",
      disabled: false,
      wxPayInfo: {
        timestamp: "",
        nonceStr: "",
        package: "",
        signType: "",
        paySign: "",
      },
      wxconfig: {
        debug: false,
        appId: "",
        timestamp: "",
        nonceStr: "",
        signature: "",
        jsApiList: ["chooseWXPay", "hideOptionMenu"],
      },
    };
  },
  created() {
    this.userId = store.getters["store/userId"];
    console.log(this.userId);
    let idcard = store.getters["store/idcard"];
    console.log(idcard);
    // this.userId = "0001052852";
    ajax
      .post("/Api/InHospitalService/GetInHospitalInfo", {
        // userID: "0001789994",
        // userName: "张粤安",
        // idCard: "533025199707123317",
        userID: this.userId,
        userName: this.userName,
        idCard: idcard,
        machineName: "wechat",
      })
      .then((res) => {
        const code = res.data.result.resultCode;
        console.log(res.data.result);
        if (code === "-1") {
          this.$dialog
            .alert({
              message: "暂未找到您的住院记录",
              confirmButtonText: "返回",
            })
            .then(() => {
              // on confirm
              this.$router
                .replace({
                  path: "/HCardL",
                })
                .catch((err) => {
                  console.log(err);
                });
            });
        } else {
          console.log(res.data.result);
          const list = res.data.result.inpatients[0];
          this.patientName = list.patientName; //住院信息:姓名
          this.deptName = list.deptName; //住院信息:科室名
          this.bedNo = list.bedNo; //床位号
          this.inpatientStatus =
            list.inState == 'I' ? "未出院" : "已出院"; //住院状态
          this.state = list.inpatientStatus;
          this.inpatientNo = list.patientNO; //住院信息:住院号
          this.inpatientDate = list.inDate;//住院信息:入院日期
          // this.dischargeDate =
          //   list.dischargeDate == "" || list.dischargeDate == null
          //     ? "暂无数据"
          //     : list.dischargeDate.substring(0, 10); //出院时间
          this.prepayCost = list.preCost; //预缴总金额
          this.totalCost = list.totCost; //费用总额
          this.balance = list.preCost-list.totCost; //余额
          this.drName = list.drName;
          inpatientSeriNo = list.inpatientSeriNo;
        }
        // this.list = lists;
        // console.log(res.data.result);
      })
      .catch((err) => {
        console.log(err.message);
        Toast("获取失败，请稍后重试！");
      });
    this.onLoad();
  },
  methods: {
    onOneclick() {
      this.moneyValue = "1000";
    },
    onTwoclick() {
      this.moneyValue = "2000";
    },
    onThreeclick() {
      this.moneyValue = "3000";
    },
    onHFeeL() {
      store.commit("store/setInpatientNo", this.inpatientNo);
      store.commit("store/setInpatientSeriNo", inpatientSeriNo);
      this.$router
        .replace({
          path: "/HFeeL",
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onHFeeR() {
      store.commit("store/setInpatientSeriNo", inpatientSeriNo);
      this.$router
        .replace({
          path: "/HFeeR",
        })
        .catch((err) => {
          console.log(err);
        });
    },
    isWeixin() {
      return /MicroMessenger/gi.test(navigator.userAgent);
    },
    initWxConfig() {
      // 初始化微信JS功能配置
      let me = this;
      return new Promise((resolve, reject) => {
        me.getWxjsSign(appconst.wechatAppId)
          .then((x) => {
            // alert(appconst.wechatAppId);
            var config = {
              appId: appconst.wechatAppId,
              timestamp: x["timestamp"],
              nonceStr: x["noncestr"],
              signature: x["sign"],
              ticket: x["ticket"],
            };
            me.wxconfig = Object.assign(me.wxconfig, config);
            wx.ready((x) => {
              if (x) {
                //
              }
              resolve(me.wxconfig);
            });
            wx.error((err) => {
              if (err) {
                //
              }
              reject(x);
            });
            wx.config(me.wxconfig);
            // alert(wx);
          })
          .catch((x) => {
            reject(x);
          });
      });
    },
    // 获取微信JS签名
    getWxjsSign() {
      let url = encodeURIComponent(location.href);
      var center = `${appconst.thirdApiCenterBaseUrl}Api/WxMpOauth/JsSign`;
      return new Promise((resolve, reject) => {
        ajax
          .get(center, {
            params: {
              appid: appconst.wechatAppId,
              url: url,
            },
          })
          .then((x) => {
            resolve(x.data.result);
            console.log("测试" + x.data.result);
          })
          .catch((x) => {
            reject(x);
            console.log(x);
          });
      });
    },
    async onLoad() {
      let me = this;
      if (me.isWeixin()) {
        me.initWxConfig();
        wx.hideOptionMenu(); // 禁用分享

        // TODO：加载订单信息用于显示
      } else {
        Toast.fail({ message: "请在微信中打开此页面", duration: 0 });
      }
    },
    onclickPay() {
      if (this.moneyValue == "") {
        Toast("预缴金额不能为空!");
        return false;
      }
      this.disabled = true;
      Toast.loading({
        message: "正在处理中，请稍后...",
        forbidClick: true,
        loadingType: "spinner",
      });
      let me = this;
      // 将要进行支付前 拟定订单
      let openId = store.getters["wechat/openid"];
      let idcard = store.getters["store/idcard"];
      let phone = store.getters["store/phone"];
      // console.log(itemName);
      // console.log(this.deptName,this.drName);
      ajax
        .post("/Api/PayService/NewOrder", {
          userID: this.userId,
          userName: this.userName,
          idCard: idcard,
          phone: phone,
          hasCard: false,
          cardNo: this.cardNo,
          title: "住院预缴",
          content:
            this.deptName +
            "|" +
            this.drName +
            "|" +
            inpatientSeriNo +
            "|" +
            this.moneyValue,
          // content:depname+'|'+dorname+'|'+regLevel+'|'+0.01,
          description:
            "微信公众号支付" + "|" + this.userName + "|" + this.moneyValue,
          // description:"微信公众号支付"+'|'+userName+'|'+0.01,
          // cost:0.01,
          cost: this.moneyValue,
          machineName: "wechat",
          wxOpenID: openId,
        })
        .then(async (res) => {
          orderid = res.data.result;
          // alert(orderid);
          if (orderid > 0) {
            await me.getWxPayInfo();
            await ajax
              .post("/Api/WechatAgentService/AgentInHospPaid", {
                openID: openId,
                // idCard: idcard,
                userName: this.userName,
                hisFromDept: this.deptName,
                hisFromDoctor: this.drName,
                hisFromCreateTime: this.inpatientDate,
                // operatorId: OperatorId,
                machineName: "wechat",
                // position: null,
                orderId: orderid,
                inpatientSeriNo:inpatientSeriNo,
                operatorId:OperatorId,
                // inpatientNo: this.inpatientNo,
                // termialType: null,
                payWay: 3,
              })
              .then(async (res) => {
                if (res.data.result === 0 || res.data.result === 2) {
                  Toast.clear();
                  wx.chooseWXPay({
                    // appId: me.wxPayInfo.appId,
                    timestamp: me.wxPayInfo.timestamp,
                    nonceStr: me.wxPayInfo.nonceStr,
                    package: me.wxPayInfo.package,
                    signType: me.wxPayInfo.signType,
                    paySign: me.wxPayInfo.paySign,
                    success: async function (res) {
                      let resop = res.errMsg;
                      // Toast(resop);
                      // Toast(orderid);
                      if (resop === "chooseWXPay:ok") {
                        //更新订单
                        // ajax
                        //   .post("/Api/InHospitalService/CreateInhospPayLog", {
                        //     idCard: idcard,
                        //     userName: this.userName,
                        //     inpatientNo: this.inpatientNo,
                        //     orderId: orderid,
                        //     payWay: "微信",
                        //     prevBalance: this.balance,
                        //     deptName: this.deptName,
                        //     status: this.state,
                        //     inDate: this.inpatientDate,
                        //     bedNo: this.bedNo,
                        //     usedCost: this.totalCost,
                        //   })
                        //   .then((res) => {
                            // console.log(res.data.message);
                            Toast("缴费成功，请注意查看缴费情况");
                            window.location.href = "/HWait";
                          // })
                          // .catch((err) => {
                          //   Toast(
                          //     "缴费成功，创建住院预缴记录失败" + err.message
                          //   );
                          // });
                      }
                      //window.location.href = "/WechatPay/Result?returnurl="+encodeURIComponent("@ViewData["returnurl"]")+"&oid=" + '@ViewData["oid"]' + "&state=" + /\w+$/.exec(res.errMsg)[0];
                    },
                    fail: async function (res) {
                      // Toast.clear();
                      if (res) {
                        this.disabled = false;
                        Toast("您已取消缴费");
                      }
                      console.log("fail");
                    },
                    cancel: async function (res) {
                      // Toast.clear();
                      if (res) {
                        this.disabled = false;
                        Toast("您已取消缴费");
                      }
                      console.log("cancel");
                    },
                  });
                } else if (res.data.result === 1) {
                  Toast.clear();
                  Toast("您已缴费成功，请及时查看缴费情况");
                } else {
                  Toast.clear();
                  Toast("缴费失败，系统异常" + res.data.result);
                }
              })
              .catch(() => {
                Toast.clear();
                // Toast("出现未知错误：" + err);
              });
          } else {
            Toast("创建订单失败");
          }
          // console.log(orderid);
        })
        .catch(() => {
          // 更新orderid
        });
    },
    async getWxPayInfo() {
      let me = this;
      //const query = $route;  // 从Url上获取订单Id
      //me.orderId = query.orderId;
      me.orderId = orderid;
      // console.log("123456");
      console.log("orderid是" + me.orderId);
      await ajax
        .post("/Api/PayService/CreatePay", {
          orderId: orderid,
          payWay: "微信公众号",
          operatorId: OperatorId,
        })
        .then((rep) => {
          let res = rep.data.result;
          console.log("res是：" + JSON.stringify(res));
          if (res.result === 1) {
            let dat = JSON.parse(res.value);
            // alert("dat是"+ JSON.stringify(dat));
            // me.wxPayInfo.appId = dat.AppId;
            me.wxPayInfo.timestamp = dat.TimeStamp;
            me.wxPayInfo.nonceStr = dat.NonceStr;
            me.wxPayInfo.package = dat.Package;
            me.wxPayInfo.signType = dat.SignType;
            me.wxPayInfo.paySign = dat.PaySign;
            // console.log("公众号Id：" + me.wxPayInfo.appId);
            console.log("时间戳" + me.wxPayInfo.timestamp);
            console.log("随机字符串" + me.wxPayInfo.nonceStr);
            console.log("订单详情拓展字符串" + me.wxPayInfo.package);
            console.log("签名方式" + me.wxPayInfo.signType);
            console.log("签名" + me.wxPayInfo.paySign);
          } else {
            this.$Dialog
              .alert({
                message: "签名验证失败",
              })
              .then(() => {
                //
              });
          }
        })
        .catch((err) => {
          Toast(err);
        });
    },
  },
};
</script>

<style scoped>
.spanStyle {
  color: #57c4b7;
  /* font-size: 12px; */
  margin-left: 8px;
}
.defaultBtn {
  width: 100%;
}
.selectBtn {
  color: rgb(255, 255, 255) !important;
  border-color: rgb(0, 200, 170) !important;
  background: rgb(0, 200, 170) !important;
  margin-right: 3px;
}
</style>
